import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { org.carlosgub.izilito.com.pages.HomePage() }

    }
    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.sections.FooterStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.sections.CopyrightStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.sections.NavHeaderStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.sections.NavLinkStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.sections.NavButtonStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.widgets.card.ArticleSectionStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.components.widgets.card.ArticleTitleStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.pages.CenterColumnStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.pages.ImageStyle)
        ctx.theme.registerComponentStyle(org.carlosgub.izilito.com.pages.TextStyle)
        ctx.theme.registerComponentVariants(org.carlosgub.izilito.com.components.sections.LogoVariant)
        org.carlosgub.izilito.com.updateTheme(ctx)
        org.carlosgub.izilito.com.initSilk(ctx)
        org.carlosgub.izilito.com.components.sections.initNavHeaderStyles(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin))

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "Izilito")
        ) { org.carlosgub.izilito.com.MyApp {
                com.varabyte.kobweb.silk.defer.renderWithDeferred { router.renderActivePage() }
            }
        }
    }
}
